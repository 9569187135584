import { graphql } from "gatsby"
import React from "react"
import Article from "../components/knowledge/Article"
import Vimeo from "../components/knowledge/Vimeo"
import Layout from "../layouts/default"
import usePageHeader from "../hooks/usePageHeader"
import BackIcon from "../images/back.inline.svg"

export default function ToolTemplate({ data }) {
  const tool = data.contentfulTool
  const relatedPosts = data.relatedPosts.nodes
  const relatedTools = data.relatedTools.nodes
  const [pageHeaderRef, pageHeaderHeight] = usePageHeader()

  return (
    <Layout>
      <main>
        <Article as="article" articleHeaderHeight={pageHeaderHeight}>
          <Article.Header ref={pageHeaderRef}>
            <Article.Back
              variant="ghost"
              size="small"
              icon={<BackIcon />}
              to="/knowledge/tools"
            />
            {tool.subtitle && (
              <Article.Subtitle>{tool.subtitle}</Article.Subtitle>
            )}
            <Article.Title as={"h1"}>{tool.title}</Article.Title>
          </Article.Header>
          <Article.Content>
            {tool.featuredVideo ? (
              <Vimeo src={tool.featuredVideo.url} />
            ) : tool.featuredImage ? (
              <Article.Image fluid={tool.featuredImage.fluid} alt={tool.featuredImage.title} title={tool.featuredImage.title} />
            ) : null}
            {tool.body && <Article.Body body={tool.body} />}
          </Article.Content>
          <Article.Aside body={tool.body} />
        </Article>
        <Article.RelatedTools tools={relatedTools} />
        <Article.RelatedPosts posts={relatedPosts} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ToolBySlug($slug: String!, $tags: [String]) {
    contentfulTool(slug: { eq: $slug }) {
      contentful_id
      slug
      featuredVideo {
        contentful_id
        url
        name
      }
      featuredImage {
        fluid(maxWidth: 720) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
      title
      subtitle
      body {
        raw
        references {
          ... on ContentfulAsset {
            title
            contentful_id
            __typename
            fluid(maxWidth: 2560) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          ... on ContentfulVimeo {
            contentful_id
            name
            url
          }
          ... on ContentfulPost {
            contentful_id
            slug
            title
            __typename
          }
        }
      }
    }
    relatedPosts: allContentfulPost(
      filter: {
        tags: { elemMatch: { contentful_id: { in: $tags } } }
        slug: { ne: "demo" }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        contentful_id
        title
        slug
        featuredVideo {
          contentful_id
          url
          name
        }
        featuredImage {
          fluid(maxWidth: 720) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
    }
    relatedTools: allContentfulTool(
      filter: {
        tags: { elemMatch: { contentful_id: { in: $tags } } }
        slug: { nin: [$slug, "demo"] }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        contentful_id
        title
        slug
        featuredVideo {
          contentful_id
          url
          name
        }
        featuredImage {
          fluid(maxWidth: 720) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
